import './App.css';

function AboutUsPage() {

  return (
    <div id="comingSoonPage" className='page'>
			<div>
      	<p id='comingSoonPageSubheader'>
					próximamente
				</p>
				<p id='comingSoonPageHeader'>
					autto
				</p>
			</div>
    </div>
  );
}

export default AboutUsPage;